





import { Component, Vue } from 'vue-property-decorator'
import Search from '../components/Search.vue'
import EditJobsTable from '../components/EditJobsTable.vue'
import { ImageEntry } from '@ht-lib/accounts-models'

@Component({
  name: 'Editing',
  components: { Search, EditJobsTable }
})
export default class extends Vue {
  search (x: ImageEntry[]) {
    console.log('Search', x)
  }
}
